import React, { Component } from "react";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryTooltip,
  VictoryScatter,
  VictoryVoronoiContainer,
  VictoryContainer
} from "victory";
import PropTypes from "prop-types";
import { roundNumber } from "../../../../../App/utils";

export default class Line extends Component {
  static propTypes = {
    /** The data to be rendered on the graph */
    data: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          y: PropTypes.number,
          fill: PropTypes.string
        }),
        PropTypes.arrayOf(
          PropTypes.shape({
            x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            y: PropTypes.number,
            fill: PropTypes.string
          })
        )
      ])
    ),
    /** Label to show on x axis */
    xLabel: PropTypes.string,
    /** Label to show on y axis */
    yLabel: PropTypes.string,
    // sets styling for x-axis
    xAxisStyle: PropTypes.object,
    // sets styling for y-axis
    yAxisStyle: PropTypes.object,
    // Preferred tick Format
    defaultTickFormat: PropTypes.bool
  };

  static defaultProps = {
    xAxisStyle: { axisLabel: { fontSize: 20, padding: 60 } },
    yAxisStyle: { axisLabel: { fontSize: 20, padding: 60 } }
  };
  getData = () => {
    const { data } = this.props;
    return data || [];
  };

  getXLabel = () => {
    const { xLabel } = this.props;
    return xLabel;
  };

  getYLabel = () => {
    const { yLabel } = this.props;
    return yLabel;
  };

  getSortedTickValues = () => {
    const { sortTickValues } = this.props;
    if (!sortTickValues) return;
    const data = this.getData();
    const xValues = new Set();

    data.forEach((lineData) => {
      if (Array.isArray(lineData)) {
        lineData.forEach(({ x }) => xValues.add(x));
      } else {
        xValues.add(lineData.x);
      }
    });
    const sortedXValues = Array.from(xValues).sort((a, b) => {
      const [aMonth, aYear] = a.split("-").map(Number);
      const [bMonth, bYear] = b.split("-").map(Number);

      if (aYear === bYear) {
        return aMonth - bMonth;
      } else {
        return aYear - bYear;
      }
    });

    return sortedXValues;
  };

  getXTick = (tick, i) => {
    const { defaultTickFormat } = this.props;
    if (defaultTickFormat) return tick;
    const length = this.getData().length;

    const maxTicks = length % 2 === 0 ? 4 : 3;

    if (i !== 0 && i !== length - 1 && i % Math.ceil(length / maxTicks) !== 0)
      return "";
    return tick;
  };

  getRoundMinBy = (min) => {
    if (min === 0) return 0;
    const places = Math.floor(Math.log10(min));
    if (places === 0) return 1;
    return Number(`1${Array(places).fill(0).join("")}`);
  };

  getYMin = () => {
    const yValues = this.getValidYValues();
    if (!yValues.length) return 0;
    const min = Math.min(...yValues);
    const roundMinBy = this.getRoundMinBy(min);
    return Math.floor(min / roundMinBy) * roundMinBy;
  };

  getYTick = (tick) => {
    if (tick >= 1000) return `${roundNumber(tick / 1000)}k`;
    return tick;
  };

  getValidYValues = () => {
    const data = this.getData();
    if (!Array.isArray(data[0])) {
      return data.map(({ y }) => y).filter((y) => y !== null);
    } else {
      return data
        .map((lineData) => {
          return lineData.map(({ y }) => y).filter((y) => y !== null);
        })
        .flat();
    }
  };

  getXAxisStyle = () => {
    const { xAxisStyle } = this.props;
    return xAxisStyle;
  };

  getYAxisStyle = () => {
    const { yAxisStyle } = this.props;
    return yAxisStyle;
  };

  getVictoryLine = () => {
    let data = this.getData();

    if (!Array.isArray(data[0])) data = [data];

    return this.getValidYValues().length ? (
      <VictoryChart
        height={400}
        width={400}
        minDomain={{
          y: this.getYMin()
        }}
        domainPadding={{ x: 20, y: 20 }}
        containerComponent={
          <VictoryVoronoiContainer className="line-graph-wrapper" />
        }
      >
        <VictoryAxis
          label={this.getXLabel()}
          tickFormat={this.getXTick}
          tickValues={this.getSortedTickValues()}
          style={this.getXAxisStyle()}
          tickLabelComponent={
            <VictoryLabel
              textAnchor={"end"}
              angle={-45}
              transform={"translate(5, -5)"}
            />
          }
        />
        <VictoryAxis
          dependentAxis={true}
          padding={{ left: 30 }}
          tickFormat={this.getYTick}
          label={this.getYLabel()}
          style={this.getYAxisStyle()}
        />

        {data.map((lineData, index) => {
          return (
            <VictoryLine
              key={`line-${index}`}
              data={lineData}
              style={{
                data: {
                  stroke: lineData[0].fill
                }
              }}
              labelComponent={
                <VictoryTooltip
                  flyoutStyle={{
                    fontSize: 1000
                  }}
                />
              }
              labels={({ datum }) => `${datum.x}: ${roundNumber(datum.y)}`}
            />
          );
        })}
        {data.map((lineData, index) => {
          return (
            <VictoryScatter
              key={`scatter-${index}`}
              data={lineData}
              size={2.5}
              style={{ data: { fill: lineData[0].fill } }}
            />
          );
        })}
      </VictoryChart>
    ) : null;
  };

  render() {
    return this.getVictoryLine();
  }
}
