import React, { Component } from "react";
import {
  DEFAULT_PAGINATION_OPTIONS,
  DELETEDDATE_QUERY_EXPRESSION,
  FEATURE_HIGHLIGHT_BASEPOLYEFFECT,
  FEATURE_HIGHLIGHT_NON_BASEPOLYEFFECT,
  FEATURE_HIGHLIGHT_EXCLUDEDEFFECT,
  LOADER_SIZE_SMALL,
  LOADER_SIZE_REGULAR,
  ALL_PAGINATION_OPTION,
  FEATURE_AREA_ATTRIBUTE
} from "../../constants";
import {
  escapeJSONNewLine,
  isValidJSON,
  toLocale,
  getSymbolFromRenderer,
  isBlankValue,
  isNullOrUndefined,
  getConvertedFeatureArea
} from "../../App/utils";
import moment from "moment";
import { FeatureListWrapper } from "../../AgBoxUIKit/plugin/layout";
import { Loader } from "../../AgBoxUIKit/core";
import {
  FeatureListCount,
  FeatureListPaginationWrap
} from "../../AgBoxUIKit/plugin/components";
import { FeatureListComponent } from "../../AgBoxUIKit/plugin";
import { defaultTheme } from "../../AgBoxUIKit";
import Pagination from "../Pagination";
import { debounce, isAbortError } from "@arcgis/core/core/promiseUtils";

export default class FeatureList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      highlightItems: {},
      activeFilters: 0,
      mapViewMouseMoveHandler: { remove: () => {} },
      mapViewMouseLeaveHandler: { remove: () => {} },
      mapViewClickHandler: { remove: () => {} },
      hoverTimeOut: null,
      layerViews: [],
      highlights3d: {},
      legendIcons: []
    };
  }

  componentDidMount() {
    this.setupMouseEvents();
    this.setupLayerViews();
    this.fetchLegendDisplayIcons();
  }

  perPageOptions = () => {
    const { orgPaginationOptions, paginationOptions } = this.props;
    if (paginationOptions) return paginationOptions;
    return orgPaginationOptions
      ? [...orgPaginationOptions, ALL_PAGINATION_OPTION]
      : DEFAULT_PAGINATION_OPTIONS;
  };

  setupLayerViews = async () => {
    const { mapView, layers } = this.props;
    if (!layers) return;
    const layerViews = await Promise.all(
      layers.map((layer) => mapView.whenLayerView(layer))
    );
    this.setState({
      layerViews
    });
  };

  allActiveFilters = () => {
    const { activeFilters } = this.props;
    return activeFilters ? activeFilters : [];
  };

  dateFormat = () => {
    const { dateFormat } = this.props;
    return dateFormat ? dateFormat : "DD/MM/YYYY";
  };

  getConvertToLocaleString = (attr) => {
    //These are fields that should not be converted to locale string
    const { notConvertedToLocaleString } = this.props;
    return (
      !notConvertedToLocaleString || !notConvertedToLocaleString.includes(attr)
    );
  };

  formatItemValue = (item, attribute, label, notConvertedToLocaleString) => {
    const {
      labels: { NO_DATE_LISTED_LABEL, LABEL_YES, LABEL_NO }
    } = this.props;
    if (!item.layer) return item.attributes[attribute];

    const { placeholders, domainValues, units } = this.props;
    const { fields } = item.layer;
    const fieldInfo = fields
      ? fields.find((field) => field.name === attribute)
      : null;
    const parsedData =
      item.attributes.customData && isValidJSON(item.attributes.customData)
        ? JSON.parse(escapeJSONNewLine(item.attributes.customData))
        : null;

    const customInfo =
      parsedData && Object.prototype.hasOwnProperty.call(parsedData, attribute)
        ? parsedData[attribute]
        : null;

    if (
      !fieldInfo &&
      !customInfo &&
      (!placeholders || !placeholders[attribute])
    )
      return "";

    let value = fieldInfo
      ? item.attributes[attribute]
      : customInfo
      ? Object.prototype.hasOwnProperty.call(parsedData[attribute], "value")
        ? parsedData[attribute].value
        : parsedData[attribute]
      : placeholders[attribute]
      ? placeholders[attribute]
      : " ";

    const type = fieldInfo
      ? fieldInfo.type
      : customInfo
      ? customInfo.type
      : "string";

    if (domainValues && domainValues[attribute]) {
      const relatedDomainValue = domainValues[attribute].find(
        (item) => item.value === value
      );
      value =
        relatedDomainValue && relatedDomainValue.title
          ? relatedDomainValue.title
          : value;
    } else if (type === "boolean") {
      value =
        value === true || value === "True" || value === "true"
          ? LABEL_YES
          : LABEL_NO;
    } else if (type === "date") {
      value =
        value && moment(value).isValid()
          ? moment(value).format(this.dateFormat())
          : NO_DATE_LISTED_LABEL;
    }
    if (attribute === FEATURE_AREA_ATTRIBUTE) {
      value = this.getFeatureAreaValue(value);
      return !isNullOrUndefined(value) ? (label ? label + value : value) : "";
    }

    if (
      this.getConvertToLocaleString(attribute) &&
      !isNullOrUndefined(value) &&
      !notConvertedToLocaleString
    ) {
      value = toLocale(value);
    }

    if (units && units[attribute] && !isNullOrUndefined(value)) {
      value = `${value} ${units[attribute]}`;
    }

    return !isNullOrUndefined(value) ? (label ? label + value : value) : "";
  };

  getFeatureAreaValue = (area) => {
    const { areaUnit } = this.props;
    if (!area) return null;
    const convertedArea = getConvertedFeatureArea(area, areaUnit.unit);
    return `${convertedArea} ${areaUnit.shortname}`;
  };

  itemTitle = (item) => {
    let attributeKey;
    const { titleField, objectIdField } = this.props;
    const { layer } = item;
    if (titleField) attributeKey = titleField;
    else if (layer && layer.displayField) attributeKey = layer.displayField;
    else attributeKey = objectIdField;

    return this.formatItemValue(item, attributeKey, null);
  };

  itemSubtitle = (item, subtitle) => {
    const { field, label, highlightColor, highlight } = subtitle;
    const { shouldHighlightSubtitle } = this.props;
    const highlightSubtitle = shouldHighlightSubtitle
      ? shouldHighlightSubtitle(item, subtitle)
      : highlight
      ? highlight
      : false;
    const notConvertedToLocaleString = subtitle?.notConvertedToLocaleString;
    return {
      highlightSubtitle,
      highlightColor:
        highlightSubtitle && highlightColor ? highlightColor : null,
      value: String(
        this.formatItemValue(item, field, label, notConvertedToLocaleString)
      )
    };
  };

  featureCount = () => {
    const { features } = this.props;
    if (!features) return 0;
    return features.length;
  };

  fetchLegendDisplayIcons = async () => {
    const { displayLegendIcons, features } = this.props;
    if (
      !displayLegendIcons ||
      !displayLegendIcons.length ||
      !features ||
      !features.length
    )
      return;
    const { legendIcons } = this.state;
    const featuresToCheckSymbol = features.filter(
      (feature) =>
        feature.layer &&
        displayLegendIcons.includes(feature.layer.geometryType) &&
        !legendIcons.find(
          (item) =>
            item.geometryType === feature.layer.geometryType &&
            item.objectId === feature.attributes.objectId
        )
    );

    for (let feature of featuresToCheckSymbol) {
      const {
        layer: { renderer, geometryType },
        attributes: { objectId }
      } = feature;
      const symbol = await getSymbolFromRenderer(renderer, feature);
      if (symbol) {
        this.setState({
          legendIcons: [
            ...this.state.legendIcons,
            {
              objectId,
              geometryType,
              emphasisIconUrl: symbol.url,
              emphasisIconColor: symbol.color ? symbol.color.toCss() : null,
              emphasisIconType: symbol.url ? null : geometryType
            }
          ]
        });
      }
    }
  };

  getEmphasisForListItem = (item) => {
    const {
      emphasis = [],
      objectIdField,
      displayLegendIcons = []
    } = this.props;
    const { legendIcons = [] } = this.state;
    if (!emphasis && !legendIcons.length) return [];
    const legendIconsForFeature = legendIcons.filter(
      ({ objectId, geometryType }) =>
        item.attributes.objectId === objectId &&
        item.layer &&
        item.layer.geometryType === geometryType
    );

    const emphasisItems = emphasis.filter((emphItem) => {
      return emphItem.features.find(
        (listItem) =>
          listItem.attributes[objectIdField] ===
            item.attributes[objectIdField] &&
          listItem.layer.title === item.layer.title
      )
        ? true
        : false;
    });
    return [...legendIconsForFeature, ...emphasisItems];
  };

  filterSubtitles = (subtitleFields) => {
    const subtitles = subtitleFields
      ? subtitleFields.filter(
          (subtitle) =>
            !subtitle.show ||
            subtitle.show === "Always" ||
            (subtitle.show === "ifExists" && !isBlankValue(subtitle.value))
        )
      : [];
    return subtitles;
  };

  listItems = () => {
    const { features, subtitleFields } = this.props;
    const subtitlesToShow = this.filterSubtitles(subtitleFields);
    if (!features) return [];
    return features.map((feature) => {
      return {
        ...feature,
        geometry: feature.geometry,
        attributes: feature.attributes,
        layer: feature.layer,
        title: this.itemTitle(feature),
        subtitles: subtitlesToShow.map((subtitle) =>
          this.itemSubtitle(feature, subtitle)
        ),
        emphasis: this.getEmphasisForListItem(feature)
      };
    });
  };

  displayItems = () => {
    const { features } = this.props;
    return features ? features : [];
  };

  featureCountText = () => {
    const {
      featureCountText,
      totalFeatureCount,
      totalListItems,
      labels: {
        RESULT_PLURAL_LABEL,
        RESULT_SINGLE_LABEL,
        RESULT_TEXT_DISPLAYED_LABEL,
        RESULT_OUT_OF_LABEL
      }
    } = this.props;
    const items = this.displayItems();
    const itemLengths = items.length;
    const countText = featureCountText
      ? featureCountText
      : itemLengths === 1
      ? RESULT_SINGLE_LABEL
      : RESULT_PLURAL_LABEL;

    const filteredCountResultText =
      totalListItems && totalListItems === 1
        ? RESULT_SINGLE_LABEL
        : RESULT_PLURAL_LABEL;

    const filteredCountText = `${RESULT_OUT_OF_LABEL} ${totalListItems} ${filteredCountResultText} ${RESULT_TEXT_DISPLAYED_LABEL}`;

    if (totalListItems && itemLengths < totalListItems)
      return filteredCountText;
    else return countText;
  };

  formatFeatureCount = () => {
    const { FEATURE_FILTERS_APPLIED_LABEL } = this.props.labels;
    const resultText = `${this.featureCount()} ${this.featureCountText()}`;
    const activeFilters = this.allActiveFilters();
    if (!activeFilters || activeFilters.length === 0) return resultText;
    const activeFiltersWithValues = activeFilters.filter((filter) => {
      const { value, domainValues, dateType } = filter;
      if (domainValues) return value !== "-";
      else if (dateType) return value && value.startDate && value.endDate;
      else return true;
    });

    if (activeFiltersWithValues.length === 0) return resultText;
    if (activeFiltersWithValues.length === 1) {
      const filter = activeFiltersWithValues[0];
      const { domainValues, value, title, dateType } = filter;
      const domainValue = domainValues
        ? domainValues.find((item) => item.value === value)
        : null;
      const selectedValue = domainValues
        ? domainValue && domainValue.title
          ? domainValue.title
          : value
        : dateType
        ? dateType === "range"
          ? `${moment(value.startDate).format("DD/MM/YYYY")} - ${moment(
              value.endDate
            ).format("DD/MM/YYYY")}`
          : moment(value.startDate).format("DD/MM/YYYY")
        : true;
      return `${resultText}, ${title} = ${selectedValue}`;
    }
    return `${resultText}, ${activeFiltersWithValues.length} ${FEATURE_FILTERS_APPLIED_LABEL}`;
  };

  handleSelectItem = (item) => {
    const { onSelect } = this.props;
    if (!onSelect) return false;
    onSelect(item);
  };
  whenLoading = () => {
    const { loading } = this.props;
    return loading;
  };
  whenNoDisplayItems = () => {
    return this.featureCount() === 0 && !this.whenLoading();
  };

  whenFeaturesVisible = () => {
    const { isSearching } = this.props;
    const displayItemsAvailable = this.featureCount() > 0;
    return isSearching
      ? false
      : displayItemsAvailable
      ? true
      : !this.whenLoading();
  };
  whenSubtitle = () => {
    const { subtitleFields } = this.props;
    return subtitleFields ? true : false;
  };
  whenShowFeatureCount = () => {
    const { showFeatureCount } = this.props;
    return showFeatureCount ? true : false;
  };
  whenHighlightListItem = (item) => {
    const { highlightItems } = this.state;
    const { disableHighlight, objectIdField } = this.props;
    if (!highlightItems || !item.layer || disableHighlight) return false;
    if (!highlightItems[item.layer.title]) return false;
    return (
      highlightItems[item.layer.title].find(
        (highlightItem) =>
          highlightItem.attributes[objectIdField] ===
            item.attributes[objectIdField] &&
          highlightItem.attributes.title === item.attributes.title
      ) !== undefined
    );
  };
  getNoFeatureMessage = () => {
    const {
      noFeaturesMessage,
      labels: { DEFAULT_NO_FEATURES_TO_DISPLAY_LABEL }
    } = this.props;
    return noFeaturesMessage
      ? noFeaturesMessage
      : DEFAULT_NO_FEATURES_TO_DISPLAY_LABEL;
  };
  getHoverTimeOut = () => {
    const { hoverTimeOut } = this.state;
    return hoverTimeOut ? hoverTimeOut : false;
  };
  featureSearchHasClickHandler = () => {
    const { featureSearchMapClickHandler } = this.props;
    return !isNullOrUndefined(featureSearchMapClickHandler);
  };
  handleUpdateLayerViewFilter = async (features) => {
    const {
      updateDefinitionExpression,
      mapView,
      webMap,
      hiddenLayers,
      layers,
      getLayerName,
      objectIdField
    } = this.props;
    if (!updateDefinitionExpression || !features) return;
    const layerFeatures = layers.reduce((result, layer) => {
      const { title } = layer;
      const featuresForLayer = features.filter(
        (feature) => feature.layer && feature.layer.title === title
      );
      return {
        ...result,
        [title]: featuresForLayer.map(
          (feature) => feature.attributes[objectIdField]
        )
      };
    }, {});

    const basePolyLayerTitle = getLayerName("basePoly");
    const isBaseFeatureList = layers
      ? layers.find((layer) => layer.title === basePolyLayerTitle)
      : false;
    if (hiddenLayers)
      hiddenLayers.forEach(async (layerItem) => {
        const layerView = await mapView.whenLayerView(layerItem);
        if (!layerView.filter || !layerView.filter.where) {
          if (layerItem.title === basePolyLayerTitle && !isBaseFeatureList)
            layerView.filter = {
              where: DELETEDDATE_QUERY_EXPRESSION
            };
          else {
            layerView.filter = {
              where: "1=2"
            };
          }
        }
      });
    for (const layerTitle of Object.keys(layerFeatures)) {
      const layer = webMap.layers.items.find(
        (layer) => layer.title === layerTitle
      );

      const layerView = await mapView.whenLayerView(layer);
      layerView.filter =
        layerFeatures[layerTitle].length > 0
          ? {
              objectIds: layerFeatures[layerTitle]
            }
          : {
              where: "1=2"
            };
    }
  };

  handleHighlightItem = (items) => {
    try {
      const {
        disableHighlight,
        getLayerName,
        objectIdField,
        has3D,
        onHighlight
      } = this.props;
      const { layerViews } = this.state;
      if (disableHighlight || !items || items.length === 0) return;
      const { layer } = items[0];
      const layerView = layerViews.find(
        (layerViewItem) => layerViewItem.layer.title === layer.title
      );
      if (!layerView) return false;
      if (has3D) {
        this.handle3DHighlightItems(items, layerView);
      } else {
        if (onHighlight) {
          onHighlight(items);
        }
        const where = items
          .map((item) => `${objectIdField} = ${item.attributes[objectIdField]}`)
          .join(" OR ");
        if (
          layerView.featureEffect &&
          where === layerView.featureEffect.filter.where
        )
          return;
        layerView.featureEffect = {
          includedEffect:
            layer.title === getLayerName("basePoly")
              ? FEATURE_HIGHLIGHT_BASEPOLYEFFECT
              : FEATURE_HIGHLIGHT_NON_BASEPOLYEFFECT,
          filter: {
            where
          },
          excludedLabelsVisible: layer.title.includes("base") ? false : true,
          excludedEffect: FEATURE_HIGHLIGHT_EXCLUDEDEFFECT
        };
      }
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
    }
  };

  handle3DHighlightItems = (items, layerView) => {
    const { highlights3d } = this.state;
    const {
      layer: { title }
    } = layerView;
    this.handleRemoveAll3dHighlights();
    const highlight = layerView.highlight(items);
    this.setState({
      highlights3d: {
        ...highlights3d,
        [title]: highlight
      }
    });
  };

  handleRemoveAll3dHighlights = () => {
    const { highlights3d } = this.state;
    const newHighlights3d = Object.keys(highlights3d).reduce((result, key) => {
      const item = highlights3d[key];
      if (item && item.remove) item.remove();
      return {
        ...result,
        [key]: null
      };
    }, {});
    this.setState({
      highlights3d: newHighlights3d
    });
  };

  handleItemHoverEvent = (item) => {
    if (this.getHoverTimeOut()) {
      clearTimeout(this.getHoverTimeOut());
    }

    const { layers, disableHighlight, objectIdField } = this.props;
    const features = this.displayItems();
    if (!layers || disableHighlight) return;
    const actualFeature = features.find(
      (feature) =>
        feature.attributes[objectIdField] === item.attributes[objectIdField]
    );
    if (!actualFeature) return;
    this.handleHighlightItem([actualFeature]);
    const layersToHide = layers.filter(
      (layer) => layer.title !== actualFeature.layer.title
    );
    layersToHide.forEach((layer) => {
      this.handleHideLayerView(layer);
    });
    this.setState({
      highlightItems: {
        [actualFeature.layer.title]: [actualFeature]
      }
    });
  };

  handleHideLayerView = (layer) => {
    const { layerViews } = this.state;
    const layerView = layerViews.find(
      (layerViewItem) => layerViewItem.layer.title === layer.title
    );
    if (!layerView) return;
    if (
      layerView.featureEffect &&
      layerView.featureEffect.filter.where === "1=2"
    )
      return;
    layerView.featureEffect = {
      filter: {
        // autocasts to FeatureFilter
        where: "1=2"
      },
      excludedLabelsVisible: layer.title.includes("base") ? false : true,
      excludedEffect: "grayscale(50%) opacity(30%)"
    };
  };

  handleRemoveAllHighlight = async () => {
    try {
      const { layerViews, highlightItems } = this.state;
      const { disableHighlight, has3D, onHighlight } = this.props;
      if (!highlightItems || !layerViews || disableHighlight) return false;
      if (has3D) {
        this.handleRemoveAll3dHighlights();
      } else {
        layerViews.forEach((layerView) => {
          layerView.featureEffect = null;
        });
        if (onHighlight) {
          onHighlight([]);
        }
      }

      this.setState({
        highlightItems: {}
      });
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
    }
  };

  handleHighlightMap = (results) => {
    const { disableHighlight, objectIdField } = this.props;
    if (disableHighlight) return;
    const { features } = this.props;
    if (!features || features.length === 0) return;
    const highlightItems = results
      .filter((item) => {
        return features.some(
          (feature) =>
            feature.attributes &&
            item.graphic.attributes &&
            feature.attributes[objectIdField] ===
              item.graphic.attributes[objectIdField] &&
            feature.layer &&
            item.layer &&
            feature.layer.title === item.layer.title
        );
      })
      .map((item) => item.graphic);
    if (highlightItems.length === 0) return this.handleRemoveAllHighlight();
    const keyedLayers = highlightItems.reduce(
      (h, obj) =>
        Object.assign(h, {
          [obj.layer.title]: (h[obj.layer.title] || []).concat(obj)
        }),
      {}
    );

    const arrayOfLayersToChange = Object.keys(keyedLayers);
    const layersToIgnore = this.props.layers.filter(
      (layer) => arrayOfLayersToChange.indexOf(layer.title) === -1
    );
    arrayOfLayersToChange.forEach((key) => {
      this.handleHighlightItem(keyedLayers[key]);
    });

    layersToIgnore.forEach((layer) => {
      this.handleHideLayerView(layer);
    });

    this.setState({
      highlightItems: keyedLayers
    });
  };

  handleMouseMove = (event) => {
    if (event.buttons > 0 || this.props.disableHighlight) return;
    if (this.featureSearchHasClickHandler()) return;
    const { mapView } = this.props;
    return mapView.hitTest(event).then((hit) => {
      const { results } = hit;
      if (results.length === 0) return this.handleRemoveAllHighlight();
      return this.handleHighlightMap(results);
    });
  };

  handleMouseClick = async (event) => {
    if (
      this.featureSearchHasClickHandler() ||
      this.props.activeWidget === "measure"
    )
      return;
    try {
      const { features } = this.props;
      if (!features) return;

      const { mapView, objectIdField } = this.props;
      const { results } = await mapView.hitTest(event);
      if (results.length === 0) return;
      const listItems = results
        .filter((item) =>
          features.find(
            (feature) =>
              feature.attributes &&
              item.graphic.attributes &&
              feature.attributes[objectIdField] ===
                item.graphic.attributes[objectIdField] &&
              feature.layer &&
              item.layer &&
              feature.layer.title === item.layer.title
          )
        )
        .map((item) => item.graphic);
      if (listItems.length === 0) return;
      const clickedFeature = listItems[0];
      this.handleRemoveAllHighlight();
      this.handleSelectItem(clickedFeature);
    } catch (e) {
      console.log(e);
      if (process.env.NODE_ENV === "development") console.log(e);
    }
  };

  whenSearching = () => {
    const { isSearching } = this.props;
    return isSearching === true;
  };
  loadingText = () => {
    const {
      labels: { LOADING_ADDITIONAL_FEATURES_LABEL, LOADING_FEATURES_LABEL }
    } = this.props;
    return this.featureCount() > 0 && !this.whenSearching()
      ? LOADING_ADDITIONAL_FEATURES_LABEL
      : LOADING_FEATURES_LABEL;
  };
  loaderSize = () => {
    return this.featureCount() > 0 || this.whenSearching()
      ? LOADER_SIZE_SMALL
      : LOADER_SIZE_REGULAR;
  };

  handleOnChangePerPage = (newLimit) => {
    const { changePerPage } = this.props;
    if (!changePerPage) return false;
    changePerPage(newLimit);
  };

  onPageSelected = (paginationInfo) => {
    if (!paginationInfo) return;
    const { handlePagination } = this.props;
    if (handlePagination) handlePagination(paginationInfo);
  };

  showPerPageDropdown = () => {
    const { totalListItems, showPaginationDropDown } = this.props;
    if (!showPaginationDropDown) return false;
    if (this.whenLoading() || this.whenSearching()) return false;
    const perPageOptions = this.perPageOptions();
    const minPerPage = Math.min(...perPageOptions);
    return totalListItems > minPerPage;
  };
  handleRemoveHover = () => {
    if (this.getHoverTimeOut()) {
      clearTimeout(this.getHoverTimeOut());
    }
    this.setState({
      hoverTimeOut: setTimeout(() => {
        this.handleRemoveAllHighlight();
      }, 300)
    });
  };
  removeLayerViewEffects = () => {
    try {
      const { has3D } = this.props;
      const { layerViews } = this.state;
      if (!layerViews) return;
      if (has3D) {
        this.handleRemoveAll3dHighlights();
      } else {
        layerViews.forEach((layerView) => {
          layerView.featureEffect = null;
        });
      }
    } catch (e) {
      if (process.env.NODE_ENV === "development") console.log(e);
    }
  };
  setupMouseEvents = () => {
    const { mapView, disableHighlight } = this.props;
    this.removeMapViewHandlers();
    if (disableHighlight) return;
    const debouncedMouseMove = debounce((event) => {
      return this.handleMouseMove(event);
    });
    const mapViewMouseMoveHandler = mapView.on("pointer-move", (event) => {
      debouncedMouseMove(event).catch(function (err) {
        if (!isAbortError(err)) {
          throw err;
        }
      });
    });

    const debouncedMouseLeave = debounce(() => {
      return this.handleRemoveAllHighlight();
    });

    const mapViewMouseLeaveHandler = mapView.on("pointer-leave", () => {
      debouncedMouseLeave().catch(function (err) {
        if (!isAbortError(err)) {
          throw err;
        }
      });
    });
    const mapViewClickHandler = mapView.on("click", this.handleMouseClick);

    this.setState({
      mapViewMouseMoveHandler,
      mapViewMouseLeaveHandler,
      mapViewClickHandler
    });
  };

  componentDidUpdate(prevProps) {
    const { features, layers, disableHighlight } = this.props;
    if (prevProps.features !== features) {
      this.handleUpdateLayerViewFilter(features ? features : []);
      this.fetchLegendDisplayIcons();
    }
    if (prevProps.layers !== layers) {
      this.setupLayerViews();
    }
    if (prevProps.disableHighlight !== disableHighlight) {
      this.setupMouseEvents();
    }
  }

  componentWillUnmount() {
    this.removeLayerViewEffects();
    this.removeMapViewHandlers();
  }

  removeMapViewHandlers = () => {
    const {
      mapViewMouseMoveHandler,
      mapViewMouseLeaveHandler,
      mapViewClickHandler
    } = this.state;

    if (mapViewMouseMoveHandler) mapViewMouseMoveHandler.remove();
    if (mapViewMouseLeaveHandler) mapViewMouseLeaveHandler.remove();
    if (mapViewClickHandler) mapViewClickHandler.remove();
  };

  paginationOptions = () => {
    const {
      labels: { SHOW_ALL_PAGINATION_LABEL }
    } = this.props;
    return this.perPageOptions().map((option) => ({
      value: option,
      title:
        option === ALL_PAGINATION_OPTION
          ? SHOW_ALL_PAGINATION_LABEL
          : toLocale(option)
    }));
  };

  isClickable = () => {
    const { onSelect } = this.props;
    return onSelect ? true : false;
  };

  render() {
    const { showPagination = true } = this.props;
    return (
      <FeatureListWrapper
        data-name={"FeatureListWrapper"}
        displayListCount={this.whenShowFeatureCount()}
        loaderSize={this.loaderSize()}
        loaderVisible={this.whenLoading() || this.whenSearching()}
      >
        {!this.whenLoading() &&
          this.whenFeaturesVisible() &&
          this.whenShowFeatureCount() && (
            <FeatureListCount>
              {toLocale(this.formatFeatureCount())}
            </FeatureListCount>
          )}
        {(this.whenLoading() || this.whenSearching()) && (
          <Loader
            loadingText={this.loadingText()}
            size={this.loaderSize()}
            visible={true}
          />
        )}
        {this.whenFeaturesVisible() && (
          <FeatureListComponent
            listItems={this.listItems()}
            objectIdField={this.props.objectIdField}
            isClickable={this.isClickable()}
            handleSelectItem={this.handleSelectItem}
            handleHoverItem={this.handleItemHoverEvent}
            handleRemoveHover={this.handleRemoveHover}
            noFeatureMessage={this.getNoFeatureMessage()}
          />
        )}
        {showPagination && (
          <Pagination
            buttonColor={defaultTheme.agBlack}
            limit={this.props.listLimit}
            total={this.props.totalListItems}
            page={this.props.listPage}
            onPageSelected={this.onPageSelected}
            loading={this.whenLoading() || this.whenSearching()}
            limitOptions={this.paginationOptions()}
            onChangeLimit={this.handleOnChangePerPage}
            showLimitDropdown={this.showPerPageDropdown()}
          />
        )}
      </FeatureListWrapper>
    );
  }
}
