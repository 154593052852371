import React, { Component } from "react";
import { GraphWrapper } from "../../layout";
import { Heading2, P } from "..";
import PropTypes from "prop-types";
import { roundNumber, isNullOrUndefined } from "../../../../App/utils";
import Pie from "./subcomponents/Pie";
import Histogram from "./subcomponents/Histogram";
import Bar from "./subcomponents/Bar";
import Legend from "./subcomponents/Legend";
import BoxPlot from "./subcomponents/BoxPlot";
import Line from "./subcomponents/Line";
import { COLOR_SCALE } from "../../../../constants";

/** Graph component which renders a different graph type based on props.chartType. Currently supports Bar and Pie graphs */
export default class Graph extends Component {
  static propTypes = {
    /** The title that will show at the top of the pie graph*/
    title: PropTypes.string,
    /** The description to go at the top of the pie graph. */
    description: PropTypes.string,
    /** An array of data objects that form the pie graph segments. */
    data: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          /** The name of the data item */
          x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          /** The title that is visible in the data, specifically used for the legend */
          xTitle: PropTypes.string,
          /** The number to enter into the graph display - if box plot, can be an array */
          y: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.number),
            PropTypes.number
          ]),
          /** The fill color for the graph item */
          fill: PropTypes.string,
          /** The min when type is boxPlot */
          min: PropTypes.number,
          /** The max when type is boxPlot */
          max: PropTypes.number,
          /** The median when type is boxPlot */
          median: PropTypes.number,
          /** The q1 value when type is boxPlot */
          q1: PropTypes.number,
          /** The q3 value when type is boxPlot */
          q3: PropTypes.number
        }),
        PropTypes.arrayOf(
          PropTypes.shape({
            /** The name of the data item */
            x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            /** The title that is visible in the data, specifically used for the legend */
            xTitle: PropTypes.string,
            /** The number to enter into the graph display - if box plot, can be an array */
            y: PropTypes.oneOfType([
              PropTypes.arrayOf(PropTypes.number),
              PropTypes.number
            ]),
            /** The fill color for the graph item */
            fill: PropTypes.string,
            /** The min when type is boxPlot */
            min: PropTypes.number,
            /** The max when type is boxPlot */
            max: PropTypes.number,
            /** The median when type is boxPlot */
            median: PropTypes.number,
            /** The q1 value when type is boxPlot */
            q1: PropTypes.number,
            /** The q3 value when type is boxPlot */
            q3: PropTypes.number
          })
        )
      ])
    ),
    /**
     * An array of css colors/rgb values/hex values that set the color of the datum in the graph
     */
    colorScale: PropTypes.arrayOf(PropTypes.string),
    /**
     * The labels on the pie graph data sections. Will be attributed to the sections in index order
     * e.g ["first", "second", "third"]
     */
    labels: PropTypes.array,
    // A boolean value for whether to show box plot labels for ,max, min, median, q1 & q3
    boxLabels: PropTypes.bool,
    /** A boolean value for whether to show a legend below the pie graph or not */
    showLegend: PropTypes.bool,
    /** Which kind of chart to render - currently only supports 'pie'*/
    chartType: PropTypes.string,
    /** Rendered when no data to show in the graph */
    noResultsLabel: PropTypes.string,
    /** The spacing between histogram bins */
    binGap: PropTypes.number,
    /** Called on click of pie segment */
    onClickSegment: PropTypes.func,
    /** determines whether to show bar graph labels */
    showBarLabels: PropTypes.bool,
    /** Array of selected graph items if controlling from outside the component */
    selectedGraphItems: PropTypes.array,
    /** Determines which type of legend to show */
    legendType: PropTypes.oneOf(["scale", "uniqueValue"]),
    /** Label to show on bar graph x axis */
    xLabel: PropTypes.string,
    /** Label to show on bar graph y axis */
    yLabel: PropTypes.string,
    /** Custom content to show inside graph */
    customContent: PropTypes.node,
    /** Custom y axis tick values */
    yTickValues: PropTypes.arrayOf(
      PropTypes.oneOfType([
        /** for bar graphs */
        PropTypes.shape({
          /** the number to display */
          y: PropTypes.number,
          /** an optional string to display for the tick label */
          tickLabel: PropTypes.string
        }),
        /**For box plots */
        PropTypes.number
      ])
    ),
    /** Sets whether you can interact with bar or pie items */
    allowClickSegments: PropTypes.bool,
    /** The data to be rendered in the legend if specifying a custiom range. Useful when you want to show all the class breaks instead of just the visible ones */
    legendValues: PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.string
      })
    ),
    /** If set to true, shows checkboxes for legend selection */
    showLegendCheckboxes: PropTypes.bool,
    /** Called when a legend checkbox is checked or unchecked */
    onChangeSelectedLegend: PropTypes.func,
    // sets the boxplot styling for min, max, median, q1 & q3
    style: PropTypes.object,
    // sets the x-axis styling for box plot
    xAxisStyle: PropTypes.object,
    // sets the y-axis styling for box plot
    yAxisStyle: PropTypes.object,
    //sets the height of the graph
    height: PropTypes.number,
    //sets the width for each bar on a bar graph
    barWidth: PropTypes.number,
    /** Custom styling to pass onto component */
    legendStyles: PropTypes.object,
    /** Whether or not using default tick bypassing tickFormat function */
    defaultTickFormat: PropTypes.bool
  };

  static defaultProps = {
    colorScale: COLOR_SCALE,
    showLegend: true,
    chartType: "pie",
    legendType: "uniqueValue",
    allowClickSegments: true,
    showLegendCheckboxes: false,
    defaultTickFormat: false
  };
  constructor(props) {
    super(props);
    this.state = {
      hoveredItem: null,
      selectedItems: []
    };
    this.timer = null;
  }
  getTitle = () => {
    const { title } = this.props;
    return title ? title : false;
  };
  getDescription = () => {
    const { description } = this.props;
    return description ? description : "";
  };

  getData = () => {
    const { data, defaultColor } = this.props;
    if (!data) return [];
    const colorScale = this.getColorScale();

    if (!Array.isArray(data[0])) {
      return data.map((data, i) => {
        if (data.fill) return data;
        return {
          ...data,
          fill: colorScale[i] ? colorScale[i] : defaultColor
        };
      });
    } else {
      return data.map((innerData, i) => {
        return innerData.map((data, j) => {
          if (data.fill) return data;
          return {
            ...data,
            fill: colorScale[i] ? colorScale[i] : defaultColor
          };
        });
      });
    }
  };

  getBinData = () => {
    const { bins } = this.props;
    return bins;
  };

  chartType = () => {
    const { chartType } = this.props;
    return chartType ? chartType : "pie";
  };

  getColorScale = () => {
    const { colorScale } = this.props;
    return colorScale;
  };

  getStyle = () => {
    const { style } = this.props;
    return style ? style : {};
  };

  getHeight = () => {
    const { height } = this.props;
    return height;
  };

  getXAxisStyle = () => {
    const { xAxisStyle } = this.props;
    return xAxisStyle;
  };

  getYAxisStyle = () => {
    const { yAxisStyle } = this.props;
    return yAxisStyle;
  };

  getYTickValues = () => {
    const { yTickValues } = this.props;
    return yTickValues;
  };

  getBoxLabels = () => {
    const { boxLabels } = this.props;
    return boxLabels;
  };

  getLabels = () => {
    const { labels } = this.props;
    return labels !== undefined
      ? labels
      : ({ datum }) =>
          datum.y > 0.01 ? roundNumber(datum.y, 2) : roundNumber(datum.y, 4);
  };
  /**
   * Returns an array of data, ordered to use for the graph legend
   *
   * @public
   */
  getLegendData = () => {
    const { legendValues, legendType } = this.props;
    if (legendValues) return legendValues;
    const data = this.getData();
    const colorScale = this.getColorScale();
    const legendData = data.map((item, i) => {
      const { xTitle, x, y, fill, unit } = item;
      return {
        name: xTitle ? xTitle : x,
        value: unit ? `${y}${unit}` : y,
        color: fill ? fill : colorScale[i],
        x
      };
    });
    //unique value legends doesn't require any further sorting
    if (legendType === "uniqueValue") return legendData;

    //Sets up the legend to only show the text for the min and max items
    const orderedLegendItems = [...legendData].sort(
      (a, b) => b.value - a.value
    );
    const max = orderedLegendItems[0];
    const min = orderedLegendItems[orderedLegendItems.length - 1];
    const uniqueColors = [
      ...new Set(orderedLegendItems.map((item) => item.color))
    ];
    return uniqueColors
      .sort((a, b) => b.value - a.value)
      .map((color, i) => {
        let name = null;
        if (color === min.color) {
          name = min.name ? min.name : min.value;
        } else if (color === max.color) {
          name = max.name ? max.name : max.value;
        }
        return {
          color,
          name
        };
      });
  };
  /**
   * Is called on focus/hover of pie slices and legend items. Sets the currently hovered/focussed on slice in state to control the effect on the pie slices and legend items.
   *
   * @param name - the x value or name of the datum
   * @public
   */
  handleHoverItem = (name) => {
    const { allowClickSegments } = this.props;
    if (allowClickSegments === false) return;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      this.setState({
        hoveredItem: name
      });
    }, 10);
  };

  /**
   * Returns whether any slices or legend items are hovered on/focussed on
   *
   * @public
   */

  anySlicesHovered = () => {
    const { hoveredItem } = this.state;
    return hoveredItem !== null;
  };

  /**
   * Returns if the passed data item is currently selected/hovered over/focussed on
   *
   * @param {string} x - the x value or name from the data item
   * @public
   */

  isHoveredItem = (x) => {
    const { hoveredItem } = this.state;
    return hoveredItem ? hoveredItem === x : false;
  };

  showLegend = () => {
    const { showLegend } = this.props;
    return !isNullOrUndefined(showLegend) ? showLegend : true;
  };

  /**
   * Creates and returns a description for assistive technologies to read about that datum. Description tells the user the value of the item.
   *
   * @param {object} datum - the single datum object from the legend
   * @public
   */
  createDesc = (datum) => {
    return `${datum.name} has a value of ${datum.value}`;
  };

  /**
   * Returns whether the data set for the pie graph is empty - i.e every item in the set has value of 0. If true, the graph will not show, and a 'no results' message will show instead
   *
   * @public
   */
  dataIsEmpty = () => {
    const data = this.getData();
    return data.length === 0;
  };
  /**
   * Returns the chart component based on the passed chartType
   * Defaults to pieGraph if chartType is not provided
   *
   * @public
   */
  graphComponent = () => {
    const type = this.chartType();
    if (!type) return null;
    switch (type) {
      case "histogram":
        return this.histogramGraph();
      case "bar":
        return this.barGraph();
      case "boxPlot":
        return this.boxPlotGraph();
      case "line":
        return this.lineGraph();
      case "pie":
      default:
        return this.pieGraph();
    }
  };

  selectedItems = () => {
    const { selectedGraphItems } = this.props;
    return selectedGraphItems ? selectedGraphItems : this.state.selectedItems;
  };

  isSelectedSegment = (segment) => {
    const selectedItems = this.selectedItems();
    return selectedItems.find((item) => item === segment) !== undefined;
  };

  updateSelectedItems = (selectedItems) => {
    const { selectedGraphItems, onClickSegment } = this.props;
    if (!selectedGraphItems) {
      this.setState({
        selectedItems
      });
    }
    if (onClickSegment) onClickSegment(selectedItems);
  };

  onClickSegment = (segment) => {
    const { allowClickSegments } = this.props;
    if (allowClickSegments === false) return;
    const selectedItems = this.selectedItems();
    const newSelectedItems = this.isSelectedSegment(segment)
      ? selectedItems.filter((item) => item !== segment)
      : [...selectedItems, segment];
    this.updateSelectedItems(newSelectedItems);
  };

  /**
   * Returns the pie chart component to render
   *
   * @public
   */
  pieGraph = () => {
    return (
      <Pie
        description={this.getDescription()}
        anySlicesHovered={this.anySlicesHovered()}
        isHoveredItem={this.isHoveredItem}
        data={this.getData()}
        labels={this.getLabels()}
        handleHoverItem={this.handleHoverItem}
        handleClickSlice={this.onClickSegment}
        selectedSegments={this.selectedItems()}
      />
    );
  };

  getColorBreaks = () => {
    const { colorBreaks } = this.props;
    return colorBreaks ? colorBreaks : [];
  };

  getDefaultColor = () => {
    const { defaultColor } = this.props;
    return defaultColor;
  };

  histogramGraph = () => {
    return (
      <Histogram
        data={this.getData()}
        bins={this.getBinData()}
        binGap={this.getBinGap()}
        colorBreaks={this.getColorBreaks()}
        defaultColor={this.getDefaultColor()}
      />
    );
  };

  showBarLabels = () => {
    const { showBarLabels } = this.props;
    return showBarLabels;
  };

  showXValues = () => {
    const { showXValues } = this.props;
    return showXValues;
  };

  getDefaultTickFormat = () => {
    const { defaultTickFormat } = this.props;
    return defaultTickFormat;
  };

  getSortedTickValues = () => {
    const { sortTickValues } = this.props;
    return sortTickValues;
  };

  barGraph = () => {
    return (
      <Bar
        data={this.getData()}
        handleHoverItem={this.handleHoverItem}
        handleClickItem={this.onClickSegment}
        selectedSegments={this.selectedItems()}
        isHoveredItem={this.isHoveredItem}
        showLabels={this.showBarLabels()}
        showXValues={this.showXValues()}
        xLabel={this.props.xLabel}
        yLabel={this.props.yLabel}
        customContent={this.props.customContent}
        yTickValues={this.props.yTickValues}
        xTickValues={this.props.xTickValues}
        barWidth={this.props.barWidth}
      />
    );
  };

  lineGraph = () => {
    return (
      <Line
        data={this.getData()}
        xLabel={this.props.xLabel}
        yLabel={this.props.yLabel}
        xAxisStyle={this.getXAxisStyle()}
        yAxisStyle={this.getYAxisStyle()}
        defaultTickFormat={this.getDefaultTickFormat()}
        sortTickValues={this.getSortedTickValues()}
      />
    );
  };

  getNoResultsLabel = () => {
    const { noResultsLabel } = this.props;
    return noResultsLabel ? noResultsLabel : "";
  };

  getBinGap = () => {
    const { binGap } = this.props;
    return !isNullOrUndefined(binGap) ? binGap : 0;
  };

  getLegendStyles = () => {
    const { legendStyles } = this.props;
    return legendStyles ? legendStyles : {};
  };

  legendComponent = () => {
    const showLegend = this.showLegend();
    if (!showLegend) return null;
    return (
      <Legend
        legendData={this.getLegendData()}
        handleHoverItem={this.handleHoverItem}
        isHoveredItem={this.isHoveredItem}
        isSelectedItem={this.isSelectedSegment}
        handleSelectItem={this.onClickSegment}
        legendType={this.props.legendType}
        showCheckboxes={this.props.showLegendCheckboxes}
        onChangeSelectedLegend={this.props.onChangeSelectedLegend}
        styles={this.getLegendStyles()}
      />
    );
  };

  resetSelectedSegments = () => {
    this.setState({
      selectedItems: []
    });
  };

  boxPlotGraph = () => {
    return (
      <BoxPlot
        data={this.getData()}
        labels={this.getBoxLabels()}
        xLabel={this.props.xLabel}
        yLabel={this.props.yLabel}
        style={this.getStyle()}
        xAxisStyle={this.getXAxisStyle()}
        yAxisStyle={this.getYAxisStyle()}
        yTickValues={this.getYTickValues()}
        height={this.getHeight()}
      />
    );
  };

  render() {
    return (
      <GraphWrapper>
        {this.getData() && (
          <React.Fragment>
            {this.getTitle() && <Heading2>{this.getTitle()}</Heading2>}
            {this.getDescription() && <P>{this.getDescription()}</P>}
            {this.dataIsEmpty() ? (
              <P>{this.getNoResultsLabel()}</P>
            ) : (
              <>
                {this.graphComponent()}
                {this.legendComponent()}
              </>
            )}
          </React.Fragment>
        )}
      </GraphWrapper>
    );
  }
}
